<template>
  <el-card style="min-height: 45rem">
    <el-table :data="list">
      <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="award" label="分成金额" show-overflow-tooltip></el-table-column>
      <el-table-column prop="created_at" label="分成时间" show-overflow-tooltip>
        <template v-slot="s">{{s.row.created_at | date}}</template>
      </el-table-column>
      <el-table-column prop="uid" label="用户编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="u_nickname" label="用户昵称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="u_phone" label="用户手机" show-overflow-tooltip></el-table-column>
      <el-table-column prop="join_time" label="用户加入时间" show-overflow-tooltip>
        <template v-slot="s">{{s.row.join_time | date}}</template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </el-card>
</template>

<script>

export default {
  name: "PoolOutLog",
  data(){
    return{
      list:[],
      page:1,
      total:0,
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    pageChange(e){
      this.page = e;
      this.load();
    },
    load(){
      this.$u.api.shop.plugin.capital.poolOutLog({page:this.page}).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
  },
}
</script>

<style scoped>

</style>